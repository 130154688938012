<template>
  <v-card>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="movimientosAfiliado"
        class="elevation-1"
        item-key="fecha"
        :loading="loading"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row class="pb-0">
              <v-col cols="12" align="start">
                <v-card-title class="pl-1 primary--text"
                  >{{ title }}
                </v-card-title>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openModal(item)"
                v-if="item.ultimoMovimiento"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar movimiento</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="deleteMovimientoAfiliadoModal(item.bcIds)"
                v-if="item.ultimoMovimiento"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar movimiento</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="handleExpansion(item, isExpanded)"
                v-bind="attrs"
                size="20"
                v-on="on"
                >{{ isExpanded ? "mdi-account-check" : "mdi-account" }}</v-icon
              >
            </template>
            <span>Usuarios</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Fecha alta:</strong>
            {{ item.fechaAlta }}
            <strong style="padding-left: 100px">Usuario alta:</strong>
            {{ item.usuarioAlta }}
            <strong style="padding-left: 100px">Fecha modificación:</strong>
            {{ item.fechaModificacion }}
            <strong style="padding-left: 100px">Usuario modificación:</strong>
            {{ item.usuarioModificacion }}
          </td>
        </template>
      </v-data-table>
      <v-dialog
        v-if="openModalEdit"
        v-model="openModalEdit"
        max-width="45%"
        @keydown.esc="closeAndReload"
        persistent
      >
        <EditMovimientoAfiliado
          :benId="benId"
          :movimientoAfiliado="movimientoAfiliado"
          @closeAndReload="closeAndReload"
        ></EditMovimientoAfiliado>
      </v-dialog>
      <DeleteDialog
        :titleProp="titleDelete"
        :maxWidth="'25%'"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="confirmDelete()"
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditMovimientoAfiliado from "@/components/modules/afiliaciones/afiliados/EditMovimientoAfiliado.vue";

export default {
  components: { PageHeader, DeleteDialog, EditMovimientoAfiliado },
  name: "GestionMovimientosAfiliado",
  props: {
    benId: {
      type: Number
    },
    afiliadoNom: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      title: `Gestión de movimientos del afiliado: ${this.afiliadoNom}`,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      showDeleteModal: false,
      idsToDelete: null,
      titleDelete: "¿Eliminar movimiento?",
      movimientosAfiliado: [],
      headers: [
        {
          text: "Fecha",
          value: "fecha",
          sortable: false
        },
        {
          text: "Tipo de movimiento",
          value: "tipoMovNombre",
          sortable: false
        },
        {
          text: "Dato anterior",
          value: "datoAnterior",
          sortable: false
        },
        {
          text: "Dato nuevo",
          value: "datoNuevo",
          sortable: false
        },
        {
          text: "Observaciones",
          value: "observaciones",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        { text: "", value: "data-table-expand", align: "end" },
      ],
      openModalEdit: false,
      loading: false,
      movimientoAfiliado: {},
      expanded: []
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadMovimientosAfiliado();
  },
  methods: {
    ...mapActions({
      getMovimientosAfiliado: "configAfiliaciones/getMovimientosAfiliado",
      deleteMovimientoAfiliado: "configAfiliaciones/deleteMovimientoAfiliado",
      setAlert: "user/setAlert"
    }),
    closeModal() {
      this.$emit("closeGestionMovimientos");
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    async loadMovimientosAfiliado() {
      this.movimientosAfiliado = [];
      this.loading = true;
      const response = await this.getMovimientosAfiliado(this.benId);
      this.movimientosAfiliado = response;
      this.loading = false;
    },
    deleteMovimientoAfiliadoModal(ids) {
      this.showDeleteModal = true;
      this.idsToDelete = ids;
    },
    async confirmDelete() {
      const response = await this.deleteMovimientoAfiliado(this.idsToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadMovimientosAfiliado();
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.movimientoAfiliado = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.movimientoAfiliado = {};
      this.loadMovimientosAfiliado();
    }
  }
};
</script>

<style lang="scss" scoped></style>
